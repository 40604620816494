import { useState } from "react";
import gql from "graphql-tag";
import { Grid2 as Grid, Button, Alert } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { useMask, format, unformat } from "@react-input/mask";
import CancelConsent from "./ConsentCancel";
import useConsentStore from "../../store/consentStore";
import { useMutation } from "@apollo/client";
import ForbiddenDomainList from "../../utils/ForbiddenDomainList";

interface ConsentFormValuesType {
  phone: string;
  email: string;
}

interface ConsentFormErrorsType {
  phone?: string;
  email?: string;
}

const UPDATE_CONSENT_ONLINE_CONTACT_INFO = gql`
  mutation UpdateConsentOnlineContactInfo($email: String, $phone: String, $textId: Int) {
    updateConsentOnlineContactInfo(email: $email, phone: $phone, textId: $textId) {
      id
      email
      phone
      ico
      userId
      text {
        id
        text
        type
        validTo
      }
    }
  }
`;

const ConsentForm = () => {
  const {
    email,
    phone,
    consentOnlineText,
    consentOnlineCreatedById,
    setConsentDialogOpen,
  } = useConsentStore();

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const phoneMaskOptions = {
    mask: '+(___) ___-___-___',
    replacement: { _: /\d/ },
    showMask: true,
  }

  const inputPhoneRef = useMask(phoneMaskOptions);
  const defaultPhoneValue = format(phone, phoneMaskOptions);

  const validation = (values: ConsentFormValuesType) => {
    const errors: ConsentFormErrorsType = {};
    const requiredMsg = "Pole musí být vyplněno";
    const errorPhoneMessage = "Neplatné telefonní číslo";
    const unformatedPhone = unformat(values.phone, phoneMaskOptions);

    const forbiddenDomains = ForbiddenDomainList;

    if (!values.email) {
      errors.email = requiredMsg;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Neplatný email";
    } else {
      const emailDomain = values.email.split("@")[1]?.toLowerCase();
      if (forbiddenDomains.includes(emailDomain)) {
        errors.email = "Musíte použít soukromý email";
      }
    }

    if (!unformatedPhone || unformatedPhone.length < 12) {
      errors.phone = requiredMsg;
    } else {
      const countryCode = unformatedPhone.slice(0, 3);
      if (countryCode === "420") {
        if (!/^420[67]\d{8}$/.test(unformatedPhone)) {
          errors.phone = errorPhoneMessage;
        }
      } else if (countryCode === "421") {
        if (!/^4219\d{8}$/.test(unformatedPhone)) {
          errors.phone = errorPhoneMessage;
        }
      } else {
        errors.phone = errorPhoneMessage;
      }
    }

    return errors;
  };

  const [updateConsentOnlineContactInfo] = useMutation(UPDATE_CONSENT_ONLINE_CONTACT_INFO, {
    refetchQueries: "all",
  });

  const handleUpdateContactInfo = async ({
    email,
    phone,
    textId,
  }: {
    email: string;
    phone: string;
    textId: number | null;
  }) => {
    await updateConsentOnlineContactInfo({
      variables: {
        email,
        phone,
        textId,
      },
    });
  };

  const handleFormSubmit = async (
    values: ConsentFormValuesType,
    setSubmitting: {
      (isSubmitting: boolean): void;
    }) => {
    const { phone: _phone, email: _email } = values;
    const unformatedPhone = unformat(_phone, phoneMaskOptions);

    setIsFormSubmitted(false);

    try {
      setSubmitting(false);
      setIsFormSubmitted(true);

      await handleUpdateContactInfo({
        email: _email,
        phone: unformatedPhone,
        textId: consentOnlineText?.id ?? null,
      });

      if (!email && !phone) {
        setConsentDialogOpen(true);
      }
    } catch (error) {
      console.error("Error updating contact info:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        validate={validation}
        initialValues={{
          email,
          phone: defaultPhoneValue,
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Grid
              container
              maxWidth={700}
              mx="auto"
              mb={2}
            >
              <Grid
                size={{ xs: 12, md: 6 }}
                textAlign={{ xs: "left", md: "right" }}
                px={{
                  xs: 0,
                  md: 1,
                }}
                mt={{
                  xs: 0,
                  md: 2,
                }}
              >
                <label htmlFor="email" style={{
                  fontWeight: "bold",
                }}>Soukromá e-mailová adresa:</label>
              </Grid>

              <Grid
                size={{ xs: 12, md: 6 }}
              >
                <Field
                  style={{
                    marginBottom: 8,
                  }}
                  id="email"
                  component={TextField}
                  name="email"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              container
              maxWidth={700}
              mx="auto"
              mb={2}
            >
              <Grid
                size={{ xs: 12, md: 6 }}
                textAlign={{ xs: "left", md: "right" }}
                px={{
                  xs: 0,
                  md: 1,
                }}
                mt={{
                  xs: 0,
                  md: 2,
                }}
              >
                <label htmlFor="phone" style={{
                  fontWeight: "bold",
                }}>Telefonní číslo:</label>
              </Grid>

              <Grid
                size={{ xs: 12, md: 6 }}
              >
                <Field
                  style={{
                    marginBottom: 8,
                  }}
                  id="phone"
                  component={TextField}
                  inputRef={inputPhoneRef}
                  placeholder="+(___) ___-___-___"
                  name="phone"
                  type="tel"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              container
              maxWidth={700}
              mx="auto"
              mb={2}
              rowGap={2}
            >
              {isFormSubmitted && (
                <Grid
                  size={12}
                  textAlign="left"
                >
                  <Alert
                    severity="success"
                  >
                    Údaje byly úspěšně uloženy
                  </Alert>
                </Grid>
              )}

              <Grid
                size={{ xs: 12, md: 6 }}
                textAlign="right"
                alignContent="center"
                px={{
                  xs: 0,
                  md: 1,
                }}
              >
                <CancelConsent />
              </Grid>

              <Grid
                size={{ xs: 12, md: 6 }}
                textAlign={{ xs: "right", md: "left" }}
              >

                <Button
                  disabled={
                    isSubmitting
                    || (values.email === email
                      && values.phone === defaultPhoneValue
                      && !consentOnlineCreatedById
                    )
                  }
                  type="submit"
                  variant="contained"
                  disableElevation
                  color="success"
                >
                  Uložit a potvrdit údaje
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConsentForm;
