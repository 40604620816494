import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import FieldFactory from "./FieldFactory";
import { DateTime } from "luxon";

//Specific component for birthdate select and birthNumber
const BirthCombo = (props) => {
  const { isDisabled, name, label, isRequired, realtedComponents } = props;

  const { values, setFieldValue } = useFormikContext();

  const [isNationalityDisabled, setNationalityDisabled] = useState(false);

  useEffect(() => {
    //94 = Ceska republika
    if (values.nationality === "204") {
      setNationalityDisabled(true);
      setBirthDate(values.birthNumber);
    } else {
      setNationalityDisabled(false);
    }
  }, [values.nationality, values.birthNumber]);

  const setBirthDate = (birthNumber) => {
    if (!birthNumber) {
      setFieldValue("birthDate", null);
      return;
    }

    //removing the backslash
    const birthString = birthNumber.replace(/\//g, "");

    if (isBirthNumberValid(birthString)) {
      const { year, month, day } = parseBirthNumber(birthString);

      let monthString = month.toString();
      let dayString = day.toString();
      let yearString = year.toString();

      if (yearString.length === 1) yearString = "0" + yearString;

      const yearTreshold = DateTime.now().year - 2000;

      if (year <= yearTreshold) {
        yearString = "20" + yearString;
      } else {
        yearString = "19" + yearString;
      }

      if (monthString.length === 1) monthString = "0" + monthString;
      if (dayString.length === 1) dayString = "0" + dayString;

      const parsedBirthDate = DateTime.fromFormat(
        monthString + "/" + dayString + "/" + yearString,
        "MM/dd/yyyy"
      );

      // console.log(parsedBirthDate.toISODate());
      setFieldValue("birthDate", parsedBirthDate.toISODate());
      //   setFieldValue("birthDate", month + "/" + day + "/" + year);
    } else {
      setFieldValue("birthDate", null);
    }
  };

  const parseBirthNumber = (birthString) => {
    let year = parseInt(birthString.substring(0, 2));
    let month = parseInt(birthString.substring(2, 4));
    let day = parseInt(birthString.substring(4, 6));

    if (month >= 21 && month <= 32) month -= 20;
    if (month >= 51 && month <= 62) month -= 50;
    if (month >= 71 && month <= 82) month -= 70;

    return { year: year, month: month, day: day };
  };

  const isBirthNumberValid = (birthNumber) => {
    //chec its number
    if (!isNumeric(birthNumber)) return false;

    const yearNumber = parseInt(birthNumber.substring(0, 2));

    //birthNumbers before year 54 had 9characters there is no control check
    if (birthNumber.length === 9 && yearNumber < 54) return true;

    if (birthNumber.length != 10) return false;

    //check division by 11
    if (parseInt(birthNumber) % 11 != 0) return false;

    return true;
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  };

  const birthDateExtraLabel = isNationalityDisabled
    ? " - vyplní se samo zadáním rodného čísla"
    : "";
  const isBirthDateRequired = isNationalityDisabled ? false : true;

  return (
    <>
      <FieldFactory
        isDisabled={isDisabled || isNationalityDisabled}
        fields={[
          {
            name: "birthDate",
            type: "date",
            label: "Datum narození" + birthDateExtraLabel,
            isRequired: isBirthDateRequired,
          },
        ]}
      />
      <FieldFactory
        isDisabled={isDisabled}
        fields={[
          {
            name: "birthNumber",
            type: "textField",
            label: "Rodné číslo",
            isRequired: true,
          },
        ]}
      />
    </>
  );
};

export default BirthCombo;
