//SETTINGS OF QUESTIONNAIRE FIELDS

export const CANDIDATE_TYPE = {
  normal: 1,
  parttimer: 2,
  annotator: 3,
};

export const FIELD_GROUP_1 = [
  {
    name: "",
    label: "",
    text: "Osobní údaje",
    variant: "h6",
    mb: 1,
    type: "typography",
  },
  { name: "firstName", label: "Jméno", type: "textField", isRequired: true },
  { name: "lastName", label: "Příjmení", type: "textField", isRequired: true },
  {
    name: "birthLastName",
    label: "Rodné přijmení",
    type: "textField",
    isRequired: true,
  },
  { name: "phone", label: "Telefon", type: "textField", isRequired: true },
  {
    name: "nationality",
    label: "Státní příslušnost",
    type: "comboSelect",
    menuItems: [],
    isRequired: true,
  },

  // birthNumber and birthDate are defined and used in BirthCombo component
  {
    name: "birthNumber",
    label: "Rodné číslo",
    type: "birthCombo",
    isRequired: true,
    componentRelated: [
      {
        name: "birthDate",
        label: "Datum narození",
        type: "date",
        isReqired: true,
      },
    ],
  },
  {
    name: "birthPlace",
    label: "Místo narození",
    type: "textField",
    isRequired: true,
  },
  {
    name: "maritalStatus",
    label: "Rodinný stav",
    type: "select",
    menuItems: [],
    isRequired: true,
  },
];

export const FIELD_GROUP_2 = [
  {
    name: "",
    label: "Trvalá adresa",
    text: "Trvalá adresa",
    variant: "h6",
    mb: 1,
    type: "typography",
  },
  {
    name: "permanentAddressStreet",
    label: "Ulice a číslo popisné/orientační",
    // type: "addressAutocomplete",
    type: "textField",
    isRequired: true,
    defaultValue: "",
    // autocompleteFields: {
    //   city: "permanentAddressCity",
    //   postNumber: "permanentAddressPostnumber",
    // },
  },
  {
    name: "permanentAddressCity",
    label: "Město",
    type: "textField",
    isRequired: true,
    defaultValue: "",
  },
  {
    name: "permanentAddressPostnumber",
    label: "PSČ",
    type: "textField",
    isRequired: true,
    defaultValue: "",
  },
  {
    name: "isContactAddressDifferent",
    label: "Kontaktní adresa je jiná než trvalá",
    type: "checkboxshowmore",
    componentToShow: [
      {
        label: "Kontaktní adresa",
        text: "Kontaktní adresa",
        variant: "h6",
        mb: 1,
        type: "typography",
      },
      {
        name: "contactAddressStreet",
        label: "Ulice a číslo popisné/orientační",
        // type: "addressAutocomplete",
        type: "textField",
        isRequired: true,
        // autocompleteFields: {
        //   city: "contactAddressCity",
        //   postNumber: "contactAddressPostnumber",
        // },
        selectId: true,
        defaultValue: "",
      },
      {
        name: "contactAddressCity",
        label: "Město",
        type: "textField",
        isRequired: true,
        selectId: true,
        defaultValue: "",
      },
      {
        name: "contactAddressPostnumber",
        label: "PSČ",
        type: "textField",
        isRequired: true,
        selectId: true,
        defaultValue: "",
      },
    ],
  },
];

export const FIELD_GROUP_2_B = [
  {
    name: "",
    label: "",
    text: "Vzdělání",
    variant: "h6",
    mb: 1,
    type: "typography",
  },
  {
    name: "educationLevel",
    label: "Úroveň vzdělání",
    type: "select",
    menuItems: [],
    isRequired: true,
  },
  {
    name: "educationCode",
    label: "Kód oboru",
    type: "textField",
    isRequired: true,
  },
  {
    name: "",
    label: "",
    text: 'Kód lze dohledat zde <a href="https://www.obory-vzdelani.cz/" target="_blank">klikni</a>',
    variant: "subtitle2",
    mb: 3,
    type: "typography",
  },
  { name: "universityTitleBefore", label: "Titul/y před", type: "textField" },
  { name: "universityTitleAfter", label: "Titul/y za", type: "textField" },
  {
    name: "educationDocuments",
    label: "Dokument o dosaženém vzdělání",
    type: "upload",
    isRequired: true,
    deliverLaterCheckbox: [
      {
        name: "isEducationDeliverFileLater",
        label: "Požadovaný dokument ještě nemám, dodám později",
        type: "checkbox",
      },
    ],
  },
];

export const FIELD_GROUP_3 = [
  {
    name: "",
    label: "",
    text: "Sociální informace",
    variant: "h6",
    mb: 1,
    type: "typography",
  },
  {
    name: "insuranceCompany",
    label: "Zdravotní pojišťovna",
    type: "select",
    menuItems: [],
    isRequired: true,
  },
  {
    name: "isNoInsuranceCompany",
    label: "Nemám zdravotní pojišťovnu",
    type: "checkbox",
    isVisible: false,
  },
  {
    name: "insuranceNumber",
    label: "Číslo pojištěnce",
    type: "textField",
    isRequired: true,
  },
  {
    name: "isHandicap",
    label: "Jste držitelem průkazu ZPS, ZTP?",
    type: "select",
    isRequired: true,
    menuItems: [
      { id: true, text: "Ano" },
      { id: false, text: "Ne" },
    ],
  },
  {
    name: "bankAccountNumber",
    label: "Číslo bankovního účtu",
    isRequired: true,
    type: "textField",
  },
  {
    name: "isOtherEmployment",
    label: "Máte ještě jiný pracovní poměr?",
    type: "select",
    isRequired: true,
    menuItems: [
      { id: true, text: "Ano" },
      { id: false, text: "Ne" },
    ],
  },

  {
    name: "isTradeLicense",
    type: "selectshowmore",
    label: "Máte nějakou živnost?",
    menuItems: [
      { id: true, text: "Ano" },
      { id: false, text: "Ne" },
    ],
    isRequired: true,
    componentToShow: [
      {
        name: "tradingLicenseNumber",
        label: "IČO",
        type: "textField",
        isRequired: true,
        selectId: true,
      },
    ],
  },
  {
    name: "isSeizure",
    type: "selectshowmore",
    label: "Máte evidované exekuční srážky?",
    menuItems: [
      { id: true, text: "Ano" },
      { id: false, text: "Ne" },
    ],
    isRequired: true,
    componentToShow: [
      {
        name: "seizureNumber",
        label: "Číslo exekučního rozhodnutí",
        type: "textField",
        isRequired: true,
        selectId: true,
      },
      {
        name: "children",
        label: "Děti",
        type: "select",
        isRequired: true,
        selectId: true,
        menuItems: [
          { id: 0, text: 0 },
          { id: 1, text: 1 },
          { id: 2, text: 2 },
          { id: 3, text: 3 },
          { id: 4, text: 4 },
          { id: 5, text: 5 },
          { id: 6, text: 6 },
          { id: 7, text: 7 },
          { id: 8, text: 8 },
          { id: 9, text: 9 },
          { id: 10, text: 10 },
        ],
      },
    ],
  },
  {
    name: "isCriminalRecord",
    label: "Máte záznam v trestním rejstříku?",
    type: "select",
    isRequired: true,
    menuItems: [
      { id: true, text: "Ano" },
      { id: false, text: "Ne" },
    ],
  },
];

export const FIELD_GROUP_4 = [
  {
    name: "",
    label: "",
    text: "Pracovní dokumenty",
    variant: "h6",
    mb: 1,
    type: "typography",
  },
  {
    name: "employmentOrigin",
    type: "selectshowmore",
    label: "Odkud přicházíš",
    menuItems: [],
    isRequired: true,
    componentToShow: [
      //adding isRequired to only one employeeFile bcs both of them represent one file upload
      {
        name: "employeeFile",
        label: "Kopie z úřadu práce",
        type: "upload",
        selectId: "840",
        isRequired: true,
        deliverLaterCheckbox: [
          {
            name: "isDeliverFileLater",
            label: "Požadovaný dokument ještě nemám, dodám později",
            type: "checkbox",
          },
        ],
      },

      {
        name: "employeeFile",
        label: "Kopie zápočtový list",
        type: "upload",
        selectId: "843",
        deliverLaterCheckbox: [
          {
            name: "isDeliverFileLater",
            label: "Požadovaný dokument ještě nemám, dodám později",
            type: "checkbox",
          },
        ],
      },
    ],
  },
];

export const FIELD_GROUP_4_ANNOTATOR = [
  {
    name: "",
    label: "",
    text: "Pracovní dokumenty",
    variant: "h6",
    mb: 1,
    type: "typography",
  },
  {
    name: "otherHealthInsurancePayer",
    type: "selectshowmore",
    label: "Odkud přicházíš",
    menuItems: [],
    isRequired: true,
    componentToShow: [
      {
        name: "companyIdentificationNumber",
        label: "IČO firmy",
        type: "textField",
        isRequired: true,
        selectId: 1,
      },
      {
        name: "employeeFile",
        label: "Kopie zápočtový list",
        type: "upload",
        selectId: 1,
        isRequired: false,
      },
      // {name: "", label: "", text: "Pokud nemáš zápočtový list, můžeš přejít na další sekci", variant:"body1", type: "typography", selectId: 1},

      {
        name: "healthInsurancePayerDocuments",
        label: "Potvrzení o studiu",
        type: "upload",
        selectId: 2,
        isRequired: true,
      },
      {
        name: "healthInsurancePayerDocuments",
        label: "Rozhodnutí o ZTP",
        type: "upload",
        selectId: 3,
      },
      {
        name: "healthInsurancePayerDocuments",
        label: "Přiznání důchodu",
        type: "upload",
        selectId: 4,
      },
      {
        name: "healthInsurancePayerDocuments",
        label: "Čestné prohlášení, že jsi na mateřské dovolené",
        type: "upload",
        selectId: 5,
      },
      {
        name: "healthInsurancePayerDocuments",
        label: "Doklad o platbě zdravotního pojištění jiným subjektem",
        type: "upload",
        selectId: 6,
      },
    ],
  },
];

export const FIELD_GROUP_5 = [
  {
    name: "",
    label: "",
    text: "Nahraj související soubory",
    variant: "h6",
    type: "typography",
    mb: 1,
  },
  { name: "otherDocuments", label: "Ostatní soubory", type: "upload" },
  {
    name: "",
    label: "",
    text: "Pokud nemáš žádný soubor na nahrání, můžeš tuto sekci přeskočit",
    variant: "body1",
    type: "typography",
  },
];

export const FIELD_GROUP_5_FOREIGNER = [
  {
    name: "idCardType",
    label: "",
    text: "",
    variant: "h6",
    mb: 1,
    type: "dynamictypography",
  },
  {
    name: "foreignerDocuments",
    label: "Kopie dokladu, který tě opravňuje k práci v ČR",
    type: "upload",
    isRequired: true,
  },
  {
    name: "",
    label: "",
    text: "Nahraj související soubory",
    variant: "h6",
    type: "typography",
    mt: 4,
  },
  { name: "otherDocuments", label: "Ostatní soubory", type: "upload" },
];

//used mainly for iteration through all fields eg. on RecapPage etc.
export const FORM_PAGE_DICT = {
  0: FIELD_GROUP_1,
  1: FIELD_GROUP_2,
  2: FIELD_GROUP_2_B,
  3: FIELD_GROUP_3,
  4: FIELD_GROUP_4,
  5: FIELD_GROUP_5,
};

export const FORM_PAGE_DICT_PARTTIMER = {
  0: FIELD_GROUP_1,
  1: FIELD_GROUP_2,
  2: FIELD_GROUP_2_B,
  3: FIELD_GROUP_3,
  4: FIELD_GROUP_4_ANNOTATOR,
  5: FIELD_GROUP_5,
};

export const FORM_PAGE_DICT_ANNOTATOR = {
  0: FIELD_GROUP_1,
  1: FIELD_GROUP_2,
  2: FIELD_GROUP_3,
  3: FIELD_GROUP_4_ANNOTATOR,
};

export const FORM_PAGE_DICT_FOREIGNER = {
  0: FIELD_GROUP_1,
  1: FIELD_GROUP_2,
  2: FIELD_GROUP_2_B,
  3: FIELD_GROUP_3,
  4: FIELD_GROUP_4,
  5: FIELD_GROUP_5_FOREIGNER,
};

export const FORM_PAGE_DICT_FOREIGNER_PARTTIMER = {
  0: FIELD_GROUP_1,
  1: FIELD_GROUP_2,
  2: FIELD_GROUP_2_B,
  3: FIELD_GROUP_3,
  4: FIELD_GROUP_4_ANNOTATOR,
  5: FIELD_GROUP_5_FOREIGNER,
};

export const FORM_PAGE_DICT_FOREIGNER_ANNOTATOR = {
  0: FIELD_GROUP_1,
  1: FIELD_GROUP_2,
  2: FIELD_GROUP_3,
  3: FIELD_GROUP_4_ANNOTATOR,
  4: FIELD_GROUP_5_FOREIGNER,
};
